export interface DIMSearchParams {
    topPercent: number
    baseStatAny: number
    baseStatTotal: number
    twoStatSum: number
    threeStatSum: number
    fourStatSum: number
    sixStatSum: number
    sixStatHighest: number
    sixStatHighestHighest: number
}

export const generateDIMSearch = (dimSearchParams: DIMSearchParams): string => {
    const {
        topPercent,
        baseStatAny,
        baseStatTotal,
        twoStatSum,
        threeStatSum,
        fourStatSum,
        sixStatSum,
        sixStatHighest,
        sixStatHighestHighest,
    } = dimSearchParams

    const template = `
(
  /* TMMania's Vault Analyzer End-Game Edition | Highlights Trash | Keeps Top ${topPercent}% Armor |
  Update Version: #0041 */

  (
    (
      (
        is:weapon (is:sunset or is:blue)
      )
      or
      (
        (is:armor -is:exotic -is:classitem)

        -(source:raid -is:dupelower -source:dcv)
        -(perkname:"artifice armor")

        -(maxbasestatvalue:any)
        -(basestat:any:>=${baseStatAny})
        -(basestat:total:>=${baseStatTotal})

        -(basestat:highest&secondhighest:>=${twoStatSum})

        -(basestat:highest&secondhighest&thirdhighest:>=${threeStatSum})

        -(basestat:highest&secondhighest&thirdhighest&fourthhighest:>=${fourStatSum})

        -(basestat:mobility&resilience&recovery&discipline&intellect&strength:>=${sixStatSum} basestat:sixthhighest:>=${sixStatHighest} basestat:highest:<${sixStatHighestHighest})
      )

      or
      (is:classitem energycapacity:<=5 -is:modded -is:locked -(source:raid -is:dupelower -source:dcv))
      or
      (is:armor is:sunset)
      or
      (is:armor is:blue -(name:"war mantis" is:gauntlets))
      or
      ((is:armor or is:weapon) and (is:common or is:uncommon))
    )
    -(is:tagged -tag:junk) -is:maxpower -power:pinnaclecap -is:inloadout -is:masterwork -(is:armor -is:armor2.0) -(is:armor source:events) -name:classified
  )
  or
  (tag:junk -is:maxpowerloadout)
)
`

    return template.replaceAll(/\t/g, "  ")
}
