import "./App.css"
import React, { useEffect, useState } from "react"
import { DIMSearchParams, generateDIMSearch } from "./tmmania/generateDIMSearch"
import { getStatBlocks } from "./tmmania/data"

const STORAGE_KEY = 'lastUsedTopPercent'

function App() {
    const [topPercent, setTopPercent] = useState(2.5)
    const [dimSearch, setDIMSearch] = useState("")

    const [baseStatAny, setBaseStatAny] = useState("")

    const [baseStatTotal, setBaseStatTotal] = useState("")
    const [twoStatSum, setTwoStatSum] = useState("")
    const [threeStatSum, setThreeStatSum] = useState("")
    const [fourStatSum, setFourStatSum] = useState("")
    const [sixStatSum, setSixStatSum] = useState("")

    const sixStatHighest = 7
    const sixStatHighestHighest = 16

    const handleChangeTopPercent = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const searchValue = parseFloat(event.target.value)

        if (isNaN(searchValue)) {
            event.target.setCustomValidity("Please enter a number.")
            event.target.reportValidity()
            setDIMSearch("")
            return
        } else if (searchValue === 0) {
            event.target.setCustomValidity("")
            event.target.reportValidity()
            setDIMSearch("")
            return
        }

        event.target.setCustomValidity("")
        event.target.reportValidity()

        setTopPercent(searchValue)
    }

    useEffect(() => {
        const startingTopPercent = localStorage.getItem(STORAGE_KEY)
        if (!startingTopPercent) {
            return;
        }

        const parsed = parseFloat(startingTopPercent)
        if (isNaN(parsed)) {
            return;
        }

        setTopPercent(parsed)
    }, [])

    useEffect(() => {
        try {
            const result = getStatBlocks(topPercent)

            const searchParams: DIMSearchParams = {
                topPercent: topPercent,
                baseStatAny: result.individual.value,
                baseStatTotal: result.total.value,
                twoStatSum: result.topTwo.value,
                threeStatSum: result.topThree.value,
                fourStatSum: result.topFour.value,
                sixStatSum: result.topSix.value,
                sixStatHighest,
                sixStatHighestHighest,
            }

            setBaseStatAny(result.individual.value.toString())

            setBaseStatTotal(result.total.value.toString())
            setTwoStatSum(result.topTwo.value.toString())
            setThreeStatSum(result.topThree.value.toString())
            setFourStatSum(result.topFour.value.toString())
            setSixStatSum(result.topSix.value.toString())


            setDIMSearch(generateDIMSearch(searchParams))

            try {
                localStorage.setItem(STORAGE_KEY, topPercent.toString())
            } catch {
                // storage is full or disallowed
            }
        } catch (error) {
            // console.error("Error", error)
            // setDIMSearch("")
        }

    }, [topPercent]);

    return (
        <div className="App">
            <header className="App-header">
                <div style={{ display: "flex" }}>
                    <div className="inputs">
                        <label>
                            Top Percent
                            <input
                                onChange={handleChangeTopPercent}
                                value={topPercent}
                                type="number"
                                step="0.001"
                                min="0"
                                max="18"
                                required={true}
                            ></input>
                        </label>
                    </div>

                </div>

                <div className="search-results">
                    <label>
                        Copy this to DIM
                        <textarea readOnly value={dimSearch}></textarea>
                    </label>
                </div>

                <form className="stats">
                    <p>
                        <label>
                            Any base stat &gt;=
                            <input readOnly value={baseStatAny}>
                            </input>
                        </label>
                    </p>

                    <p><label>baseStatTotal <input readOnly value={baseStatTotal}></input> </label></p>
                    <p><label>twoStatSum <input readOnly value={twoStatSum}></input> </label></p>
                    <p><label>threeStatSum <input readOnly value={threeStatSum}></input> </label></p>
                    <p><label>fourStatSum <input readOnly value={fourStatSum}></input> </label></p>
                    <p><label>sixStatSum <input readOnly value={sixStatSum}></input> </label></p>

                </form>
            </header>
        </div>
    )
}

export default App
