interface StatBlock {
    value: number
    percent: number
    cumulativePercent: number
    digressivePercent: number
}

interface StatBlocks {
    total: StatBlock
    individual: StatBlock
    highest: StatBlock
    topTwo: StatBlock
    topThree: StatBlock
    topFour: StatBlock
    topSix: StatBlock
}

export function getStatBlocks(topPercent: number): StatBlocks {
    return {
        total: getTotal(topPercent),
        individual: getIndividualStat(topPercent),
        highest: getHighestStatValue(topPercent),
        topTwo: getTopTwoAverageStatValues(topPercent),
        topThree: getTopThreeAverageStatValues(topPercent),
        topFour: getTopFourAverageStatValues(topPercent),
        topSix: getTopSixAverageStatValues(topPercent),
    }
}

export function getTotal(topPercent: number): StatBlock {
    const statTotals = [
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
        62, 63, 64, 65, 66, 67, 68,
    ]

    const percentRarity = [
        0.00037, 0.0022, 0.011, 0.039, 0.116, 0.297, 0.664, 1.33, 2.39, 3.91,
        5.85, 7.99, 10.04, 11.57, 12.22, 11.84, 10.42, 8.3, 5.95, 3.73, 2.02,
        0.921, 0.312, 0.065, 0.006,
    ]

    const cumulativePercent = [
        0.00037, 0.0026, 0.013, 0.052, 0.168, 0.465, 1.13, 2.46, 4.85, 8.77,
        14.62, 22.61, 32.64, 44.21, 56.43, 68.27, 78.69, 86.99, 92.94, 96.67,
        98.7, 99.62, 99.93, 99.99, 100,
    ]

    const digressivePercent = [
        100, 99.9996, 99.997, 99.987, 99.95, 99.83, 99.53, 98.87, 97.54, 95.15,
        91.23, 85.38, 77.39, 67.36, 55.79, 43.57, 31.73, 21.31, 13.01, 7.06,
        3.33, 1.3, 0.38, 0.07, 0,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable total")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}

export function getIndividualStat(topPercent: number): StatBlock {
    const statTotals = [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30,
    ]

    const percentRarity = [
        16.12, 0, 0, 0, 12.89, 9.38, 8.21, 5.86, 6.1, 5.51, 6.99, 5.9, 5.33,
        3.8, 4.05, 2.24, 1.92, 1.49, 1.45, 0.959, 0.682, 0.426, 0.266, 0.149,
        0.128, 0.064, 0.048, 0.0213, 0.0213,
    ]

    const cumulativePercent = [
        16.12, 16.12, 16.12, 16.12, 29.01, 38.39, 46.59, 52.45, 58.55, 64.06,
        71.05, 76.95, 82.28, 86.08, 90.13, 92.37, 94.29, 95.78, 97.23, 98.19,
        98.88, 99.3, 99.57, 99.72, 99.85, 99.91, 99.96, 99.98, 100,
    ]

    const digressivePercent = [
        100, 83.88, 83.88, 83.88, 83.88, 70.99, 61.61, 53.41, 47.55, 41.45,
        35.94, 28.95, 23.05, 17.72, 13.92, 9.87, 7.63, 5.71, 4.22, 2.77, 1.81,
        1.12, 0.7, 0.43, 0.28, 0.15, 0.09, 0.04, 0,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable individual stat")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}

export function getHighestStatValue(topPercent: number): StatBlock {
    const statTotals = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

    const percentRarity = [
        4.679, 3.983, 4.401, 3.14, 2.37, 1.52, 1.04, 0.55, 0.48, 0.23, 0.22,
        0.1, 0.1,
    ]

    const cumulativePercent = [
        81.881, 85.864, 90.27, 93.4, 95.77, 97.3, 98.33, 98.88, 99.36, 99.59,
        99.81, 99.9, 100.0,
    ]

    const digressivePercent = [
        23, 18.12, 14.14, 9.73, 6.6, 4.23, 2.7, 1.67, 1.12, 0.64, 0.41, 0.19,
        0.1,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable highest stat")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}

export function getTopTwoAverageStatValues(topPercent: number): StatBlock {
    const statTotals = [
        10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5,
        17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5,
        24, 24.5, 25, 25.5, 26, 26.5, 27.0, 27.5, 28.0, 28.5, 29.0, 29.5, 30.0,
    ]

    const percentRarity = [
        0.051, 0.264, 0.893, 1.95, 3.44, 4.99, 6.34, 7.73, 9.02, 8.97, 9.31,
        8.03, 7.36, 6.23, 5.43, 4.54, 3.76, 2.96, 2.32, 1.77, 1.34, 0.993,
        0.733, 0.515, 0.361, 0.242, 0.166, 0.106, 0.07, 0.044, 0.028, 0.016,
        0.0097, 0.0054, 0.0031, 0.0016, 0.00094, 0.00043, 0.00022, 0.000082,
        0.000041,
    ]

    const cumulativePercent = [
        0.051, 0.315, 1.21, 3.16, 6.6, 11.59, 17.93, 25.66, 34.68, 43.65, 52.96,
        60.99, 68.35, 74.58, 80.01, 84.56, 88.32, 91.28, 93.59, 95.36, 96.7,
        97.7, 98.43, 98.944, 99.305, 99.547, 99.713, 99.819, 99.889, 99.934,
        99.962, 99.978, 99.98818, 99.9936, 99.99667, 99.99828, 99.99922,
        99.99965, 99.99988, 99.99996, 100,
    ]

    const digressivePercent = [
        100, 99.95, 99.68, 98.79, 96.84, 93.4, 88.41, 82.07, 74.34, 65.32,
        56.35, 47.04, 39.01, 31.65, 25.42, 19.99, 15.44, 11.68, 8.72, 6.41,
        4.64, 3.3, 2.3, 1.572, 1.056, 0.695, 0.453, 0.287, 0.181, 0.111, 0.066,
        0.038, 0.02156, 0.01182, 0.0064, 0.00333, 0.00172, 0.00078, 0.00035,
        0.00012, 0,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable top two average")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}

export function getTopThreeAverageStatValues(topPercent: number): StatBlock {
    const statTotals = [
        8.66, 9, 9.33, 9.66, 10, 10.33, 10.66, 11, 11.33, 11.66, 12, 12.33,
        12.66, 13, 13.33, 13.66, 14, 14.33, 14.66, 15, 15.33, 15.66, 16, 16.33,
        16.66, 17, 17.33, 17.66, 18, 18.33, 18.66, 19, 19.33, 19.66, 20, 20.33,
        20.66,
    ]

    const percentRarity = [
        0.00016, 0.0012, 0.011, 0.063, 0.219, 0.6, 1.27, 2.22, 3.3, 4.41, 5.45,
        6.44, 7.25, 7.93, 8.28, 8.35, 7.97, 7.28, 6.37, 5.33, 4.38, 3.49, 2.72,
        2.07, 1.53, 1.09, 0.735, 0.483, 0.31, 0.194, 0.119, 0.072, 0.04, 0.02,
        0.009, 0.0034, 0.00061,
    ]

    const cumulativePercent = [
        0.00016, 0.00132, 0.0122, 0.07, 0.29, 0.89, 2.16, 4.38, 7.69, 12.1,
        17.55, 23.99, 31.24, 39.16, 47.45, 55.8, 63.77, 71.05, 77.41, 82.74,
        87.12, 90.61, 93.33, 95.4, 96.93, 98.01, 98.75, 99.232, 99.542, 99.736,
        99.856, 99.9272, 99.9668, 99.98688, 99.99595, 99.99939, 100,
    ]

    const digressivePercent = [
        100, 99.9998, 100.0, 99.99, 99.93, 99.71, 99.11, 97.84, 95.62, 92.31,
        87.9, 82.45, 76.01, 68.76, 60.84, 52.55, 44.2, 36.23, 28.95, 22.59,
        17.26, 12.88, 9.39, 6.67, 4.6, 3.07, 1.99, 1.251, 0.768, 0.458, 0.264,
        0.1444, 0.0728, 0.03317, 0.01312, 0.00405, 0,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable top three average")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}

export function getTopFourAverageStatValues(topPercent: number): StatBlock {
    const statTotals = [
        8, 8.25, 8.5, 8.75, 9, 9.25, 9.5, 9.75, 10, 10.25, 10.5, 10.75, 11,
        11.25, 11.5, 11.75, 12, 12.25, 12.5, 12.75, 13, 13.25, 13.5, 13.75, 14,
        14.25, 14.5, 14.75, 15, 15.25, 15.5, 15.75, 16,
    ]

    const percentRarity = [
        0.0001635, 0.00082, 0.0039, 0.014, 0.046, 0.13, 0.314, 0.665, 1.24,
        2.05, 3.07, 4.17, 5.25, 6.19, 7.03, 7.65, 8.09, 8.25, 8.08, 7.58, 6.85,
        5.91, 4.9, 3.94, 3.03, 2.2, 1.51, 0.942, 0.522, 0.252, 0.099, 0.026,
        0.0029,
    ]

    const cumulativePercent = [
        0.000163, 0.00098, 0.0049, 0.019, 0.065, 0.195, 0.509, 1.17, 2.42, 4.47,
        7.54, 11.71, 16.96, 23.15, 30.18, 37.83, 45.92, 54.17, 62.24, 69.83,
        76.67, 82.58, 87.48, 91.42, 94.45, 96.65, 98.156, 99.099, 99.62, 99.872,
        99.971, 99.997, 100,
    ]

    const digressivePercent = [
        100, 99.9998, 99.999, 100.0, 99.98, 99.94, 99.8, 99.49, 98.83, 97.58,
        95.53, 92.46, 88.29, 83.04, 76.85, 69.82, 62.17, 54.08, 45.83, 37.76,
        30.17, 23.33, 17.42, 12.52, 8.58, 5.55, 3.353, 1.844, 0.901, 0.38,
        0.128, 0.029, 0,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable top four average")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}

export function getTopSixAverageStatValues(topPercent: number): StatBlock {
    const statTotals = [
        7.33, 7.5, 7.66, 7.83, 8, 8.16, 8.33, 8.5, 8.66, 8.83, 9, 9.16, 9.33,
        9.5, 9.66, 9.83, 10, 10.16, 10.33, 10.5, 10.66, 10.83, 11, 11.16,
    ]

    const percentRarity = [
        0.00016, 0.0008, 0.0038, 0.0127, 0.0359, 0.09, 0.194, 0.384, 0.684,
        1.11, 1.66, 2.27, 2.86, 3.31, 3.49, 3.4, 2.98, 2.35738, 1.67, 1.017,
        0.534, 0.225, 0.0641, 0.01014,
    ]

    const cumulativePercent = [
        71.65, 71.65, 71.65, 71.67, 71.7, 71.79, 71.99, 72.37, 73.05, 74.16,
        75.83, 78.09, 80.95, 84.26, 87.75, 91.15, 94.12, 96.48, 98.15, 99.17,
        99.7, 99.93, 99.99, 100.0,
    ]

    const digressivePercent = [
        28.352, 28.352, 28.351, 28.347, 28.335, 28.299, 28.209, 28.015, 27.631,
        26.947, 25.836, 24.174, 21.907, 19.049, 15.743, 12.249, 8.854, 5.877,
        3.519, 1.85, 0.833, 0.299, 0.074, 0.01,
    ]

    const index = cumulativePercent.findIndex((el) => el > 100 - topPercent) - 1
    if (index < 0) {
        throw new Error("Unavailable top six average")
    }

    return {
        value: statTotals[index],
        percent: percentRarity[index],
        cumulativePercent: cumulativePercent[index],
        digressivePercent: digressivePercent[index],
    }
}
